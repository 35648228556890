<template>
  <!-- 实时监控模块 -->
  <div>
    <!-- 头部开始 -->
    <el-row class="pur-top">
        <el-col :span="3">
            <span class="pur-size">{{ purTitle }}</span>
        </el-col>
        <el-col :span="21" class="pur-right">
            <el-button type="primary" size="small" @click="Allcars">查看全部车辆</el-button>
            <el-button size="small" icon="el-icon-video-pause" v-if="play && displayMap == 2" @click="stopcars">轨迹回放暂停</el-button>
            <el-button size="small" icon="el-icon-video-play" v-if="!play && displayMap == 2" @click="gocars">轨迹回放开始</el-button>
        </el-col>
    </el-row>
    <!-- 头部结束 -->
    <!-- 内容开始 -->
    <div class="pur-nav">
        <div class="newBoxmap" v-if="displayMap == 0">
            <baidu-map class="bm-view" MapType="BMAP_SATELLITE_MAP" :center="centermap" :zoom="8" :scroll-wheel-zoom="true" :continuous-zoom="true">
                <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                <!-- 地图类型 -->
                <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_LEFT" :offset="{width:100,height:20}"></bm-map-type>
                <!-- 点控件 -->
                <bm-marker :dragging="false"  v-for="(v,k) in ArrsocketData" :key="k" :position="{lng: v.lng, lat: v.lat}" :icon="{url: require('../assets/zcar.png'), size: {width:52, height:26}}">
                    <bm-label :content="k" :offset="{width: 0, height: -24}"/>
                </bm-marker>
            </baidu-map>
        </div>
        <div class="newBoxmap" v-if="displayMap == 1">
            <baidu-map class="bm-view" MapType="BMAP_SATELLITE_MAP" :center="centermap" :zoom="19" :scroll-wheel-zoom="true" :continuous-zoom="true">
                <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                <!-- 地图类型 -->
                <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_LEFT" :offset="{width:100,height:20}"></bm-map-type>
                <!-- 点控件 -->
                <bm-marker :position="{lng:markerPoint.lng,lat:markerPoint.lat}" :dragging="false" :icon="{url: require('../assets/zcar.png'), size: {width:52, height:26}}">
                    <!-- 轨迹点详情弹框 -->
                    <bm-info-window :show="show" @close="infoWindowClose" style="width:360px;">
                        <div class="infobmBox">
                            <div class="infobmTitle">
                                <i class="el-icon-alituijianqiche"></i>
                                <span> {{markerPoint.licensePlate}}</span>
                            </div>
                            <div class="infobmConter">
                                <ul>
                                    <li style="width:25%;">描述：</li>
                                    <li>{{markerPoint.licensePlate}}</li>
                                </ul>
                                <ul>
                                    <li style="width:25%;">状态：</li>
                                    <li v-if="BAddress.state == 1">在线</li>
                                    <li v-if="BAddress.state == 2">离线</li>
                                </ul>
                                <ul>
                                    <li style="width:25%;">地址：</li>
                                    <li>{{markerPoint.address}}</li>
                                </ul>
                                <ul>
                                    <li style="width:25%;">定位：</li>
                                    <li>{{markerPoint.lng}},{{markerPoint.lat}}</li>
                                </ul>
                                <ul>
                                    <li style="width:25%;">时间：</li>
                                    <li v-if="markerPoint.createTime">{{markerPoint.createTime}}</li>
                                </ul>
                            </div>
                            <div class="infobmBottom">
                                <div class="infobottomright" @click="handlerClick(false)">
                                    <i class="el-icon-aliwuliuguiji"></i>
                                    <span> 轨迹</span>
                                </div>
                            </div>
                        </div>
                    </bm-info-window>
                </bm-marker>
            </baidu-map>
        </div>
        <div class="newBoxmap" v-if="displayMap == 2">
            <baidu-map class="bm-view" MapType="BMAP_SATELLITE_MAP" :center="centermap" :zoom="zoom" :scroll-wheel-zoom="true" :continuous-zoom="true">
                <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                <!-- 地图类型 -->
                <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_LEFT" :offset="{width:100,height:20}"></bm-map-type>
                <!-- 起始点路线规划控件 -->
                <!-- <bm-driving :panel="false" :autoViewport="true" ></bm-driving> -->
                <!-- 路书轨迹回放功能 -->
                <bml-lushu
                @stop="stopcars"
                @start="gocars"
                :path="path"
                :icon="icon"
                :play="play"
                :speed="100"
                :autoView="true"
                :content="vehicles"
                :rotation="true">
                </bml-lushu>
                <!-- 折线地图 -->
                <bm-polyline :path="polylinePath" stroke-color="red" :stroke-opacity="0.6" :stroke-weight="8" :editing="false"></bm-polyline>
            </baidu-map>
        </div>
        <div class="mapBox">
            <el-collapse v-model="activeNames">
                <el-collapse-item name="1">
                    <template slot="title">
                        <div class="mapsize">实时监控管理系统</div>
                    </template>
                    <!-- tab选项卡 -->
                    <div class="menu-list">
                        <div class="totalTab"
                            :class="{ active: item.id === sel }"
                            v-for="item in tabs"
                            :key="item.id"
                            @click="select(item)" style="cursor: pointer;">
                            <i :class="item.icon"></i>
                            <span> {{ item.label }}</span>
                            <div class="stripnone" :style="item.width"
                            :class="{ strip: item.id === sel }">
                            </div>
                        </div>
                    </div>
                    <div class="parNavs">
                        <!-- 实时监控 -->
                        <div v-show="sel === 1">
                            <div class="mapSearch">
                                <el-input
                                v-if="activeName == 'allline'"
                                @keyup.enter.native="search"
                                size="small"
                                placeholder="请输入内容"
                                prefix-icon="el-icon-search"
                                v-model="purSearch"
                                >
                                </el-input>
                                <el-input
                                v-if="activeName == 'online'"
                                @keyup.enter.native="search"
                                size="small"
                                placeholder="请输入内容"
                                prefix-icon="el-icon-search"
                                v-model="purSearch"
                                >
                                </el-input>
                                <el-input
                                v-if="activeName == 'offline'"
                                @keyup.enter.native="search"
                                size="small"
                                placeholder="请输入内容"
                                prefix-icon="el-icon-search"
                                v-model="purSearch"
                                >
                                </el-input>
                            </div>
                            <!-- 表格 -->
                            <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
                                <el-tab-pane :label="'全部(' + total +')'" name="allline">
                                    <div class="maptableAll">
                                        <ul v-for="(v,key) in mapAllData" :key='key' @click="mapAllClick(v)">
                                            <li style="text-align: center;width:15%;">
                                                <i class="el-icon-alituijianqiche"></i>
                                            </li>
                                            <li style="width:60%;">{{v.licensePlate}}</li>
                                            <li style="text-align: center;width:25%;" v-if="v.state == 1">在线</li>
                                            <li style="text-align: center;width:25%;" v-if="v.state == 2">离线</li>
                                        </ul>
                                    </div>
                                    <div class="mapPages">
                                        <el-pagination
                                        small
                                        :current-page.sync="current"
                                        :page-size="size"
                                        layout="prev, pager, next, jumper"
                                        background
                                        :total="total"
                                        @size-change="getpurcontype"
                                        @current-change="getpurcontype">
                                        </el-pagination>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane :label="'在线(' + totalone +')'" name="online">
                                    <div class="maptableAll">
                                        <ul v-for="(v,key) in mapAllDataone" :key='key' @click="mapAllClick(v)">
                                            <li style="text-align: center;width:15%;">
                                                <i class="el-icon-alituijianqiche"></i>
                                            </li>
                                            <li style="width:60%;">{{v.licensePlate}}</li>
                                            <li style="text-align: center;width:25%;">{{v.speed/10}}km/h</li>
                                        </ul>
                                    </div>
                                    <div class="mapPages">
                                        <el-pagination
                                        small
                                        :current-page.sync="currentone"
                                        :page-size="sizeone"
                                        layout="prev, pager, next, jumper"
                                        background
                                        :total="totalone"
                                        @size-change="getpurcontypeone"
                                        @current-change="getpurcontypeone">
                                        </el-pagination>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane :label="'离线(' + totaltwo +')'" name="offline">
                                    <div class="maptableAll">
                                        <ul v-for="(v,key) in mapAllDatatwo" :key='key' @click="mapAllClick(v)">
                                            <li style="text-align: center;width:15%;">
                                                <i class="el-icon-alituijianqiche"></i>
                                            </li>
                                            <li style="width:60%;">{{v.licensePlate}}</li>
                                        </ul>
                                    </div>
                                    <div class="mapPages">
                                        <el-pagination
                                        small
                                        :current-page.sync="currenttwo"
                                        :page-size="sizetwo"
                                        layout="prev, pager, next, jumper"
                                        background
                                        :total="totaltwo"
                                        @size-change="getpurcontypetwo"
                                        @current-change="getpurcontypetwo">
                                        </el-pagination>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                        <!-- 轨迹查询 -->
                        <div v-show="sel === 2">
                            <div class="mapSearch">
                                <!-- <el-date-picker
                                v-model="value1"
                                style="width: 100%;margin-bottom:12px;"
                                size="small"
                                type="date"
                                placeholder="选择日期">
                                </el-date-picker> -->
                                <el-date-picker
                                size="small"
                                @change="Times"
                                v-model="TimeArrays"
                                type="datetimerange"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyyMMddHHmmss"
                                style="width: 100%;margin-bottom:12px;">
                                </el-date-picker>
                                <!-- <el-select size="small" v-model="fenceType" placeholder="请选择围栏" @change="wlType" style="width: 100%;margin-bottom:12px;">
                                    <el-option
                                    v-for="item in fenceTypeoptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select> -->
                                <el-input
                                @keyup.enter.native="listsearchs"
                                size="small"
                                placeholder="请输入车牌号"
                                prefix-icon="el-icon-search"
                                v-model="listSearch">
                                </el-input>
                            </div>
                            <div class="trajectoryBox">
                                <ul v-for="(v,key) in listmapData" :key='key' @click="trajectClick(v.licensePlate,true)">
                                    <li style="text-align: center;width:15%;">
                                        <i class="el-icon-alituijianqiche"></i>
                                    </li>
                                    <li style="width:60%;">{{v.licensePlate}}</li>
                                    <li style="text-align: center;width:25%;" v-if="v.state == 1">在线</li>
                                    <li style="text-align: center;width:25%;" v-if="v.state == 2">离线</li>
                                </ul>
                            </div>
                            <div class="trajmapPages">
                                <el-pagination
                                small
                                :current-page.sync="listcurrent"
                                :page-size="listsize"
                                layout="prev, pager, next, jumper"
                                background
                                :total="listtotal"
                                @size-change="handlerData"
                                @current-change="handlerData">
                                </el-pagination>
                            </div>
                        </div>
                        <!-- 路线绑定 -->
                        <!-- <div v-show="sel === 3">
                            <div class="Alignment">
                                <el-select v-model="Alignmentvalue" filterable placeholder="请选择路线" style="width:100%;">
                                    <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-input v-model="input" placeholder="请输入偏移距离（单位：米）"></el-input>
                                <el-button type="primary" style="width:100%;">主要按钮</el-button>
                            </div>
                        </div> -->
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
    <!-- 内容结束 -->
  </div>
</template>
<script>
import {BmlLushu} from 'vue-baidu-map'
import zcar from '../assets/zcar.png'
import gcoord from 'gcoord'
export default {
    components: {
        BmlLushu,
    },
    data() {
        return {
            displayMap:0,   // 显示全部车辆点
            routerboday:null,
            activeNames:[],
            play: false,
            centermap:{lng:112.504527, lat:37.805348},
            markerPoint:{},
            show: false,
            BAddress:{},
            path: [],
            icon: {
                url: zcar,
                size: {width: 52, height: 26},
                opts: {anchor: {width: 27, height:13}}
            },
            purTitle: "",           // 标题 
            // 一级选项卡
            sel: 1,   // 选项卡默认显示tab
            tabs: [
                { label: "实时监控", id: 1 ,width:"33.33%",icon:'el-icon-edit'},
                { label: "轨迹查询", id: 2 ,width:"33.33%",icon:'el-icon-edit'},
                // { label: "路线绑定", id: 3 ,width:"33.33%",icon:'el-icon-edit'},
            ],
            purSearch:'',                           // 实时监控搜索
            activeName: 'allline',                  // 实时监控tab栏
            current:1,                              // 实时监控（全部）当前页数
            size:9,                                 // 实时监控（全部）显示条数
            total:0,                                // 实时监控（全部）总条数
            mapAllData:[],                          // 实时监控（全部）数据
            currentone:1,                           // 实时监控（在线）当前页数
            sizeone:9,                              // 实时监控（在线）显示条数
            totalone:0,                             // 实时监控（在线）总条数
            mapAllDataone:[],                       // 实时监控（在线）数据
            currenttwo:1,                           // 实时监控（离线）当前页数
            sizetwo:9,                              // 实时监控（离线）显示条数
            totaltwo:0,                             // 实时监控（离线）总条数
            mapAllDatatwo:[],                       // 实时监控（离线）数据
            // 轨迹查询
            socket:null,
            ArrsocketData:{},
            // 单辆车轨迹信息
            listmapData:[],                         // 轨迹查询数据
            listcurrent:1,                          // 轨迹查询当前页数
            listsize:8,                             // 轨迹查询显示条数
            listtotal:0,                            // 轨迹查询总条数
            TimeArrays:[],                          // 日期搜索
            fenceType:'',                           // 选中的围栏名称
            fenceTypeoptions:[],                    // 围栏名称
            listSearch:'',                          // 轨迹查询搜索
            polylinePath: [],                       // 自定义轨迹集合
            newlinePath:[],                         // 原折线轨迹
            // 路书
            startAddress:{
                lng:'',
                lat:''
            },
            vehicles:'',
            zoom:7,
            // 路线绑定
            Alignmentvalue:'',
            options: [],
            arraddres:'',
        };
    },
    created() {
        this.times();
        this.newjkFlas();
        this.routerboday = setInterval(this.newjkFlas,5000);
        this.purTitle = this.$route.meta.title;
        this.getpurcontype();       // 全部
        this.getpurcontypeone();    // 在线
        this.getpurcontypetwo();    // 离线
        this.activeNames = [];
        this.listSearch = '';
    },
    mounted(){
        this.initsocket();
    },
    methods: {
        // 查看全部车两
        Allcars(){
            this.displayMap = 0;
            this.shoe = true;
            // this.times();
            this.newjkFlas();
            this.routerboday = setInterval(this.newjkFlas,5000);
            this.sel = 1;
            this.activeName = 'allline';
            this.getpurcontype();       // 全部
            // this.listSearch = '';
            this.$forceUpdate();
        },
        // 实时推送所有车辆的点
        newjkFlas(){
            this.ArrsocketData = {};
            this.api.riskCar.allData({ssjkFlag:true})
            .then(res=>{
                if(res.data.code == 200){
                    // console.log(res.data.data)
                    for(let i=0;i<res.data.data.length;i++){
                        // debugger
                        if(res.data.data[i].lng && res.data.data[i].lat){
                            let result = gcoord.transform([res.data.data[i].lng,res.data.data[i].lat],gcoord.WGS84,gcoord.BD09);
                            this.ArrsocketData[res.data.data[i].licensePlate] = {
                                lng:result[0],
                                lat:result[1]
                            }
                            this.$forceUpdate();
                        }

                    }
                }
            })
        },
        times(){
            let date = new Date();
            var Y = date.getFullYear()
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
            let arr0 =  Y.toString() + M.toString() + D + '000000';
            let arr1 = Y.toString() + M.toString() + D + '235959'
            this.TimeArrays = [arr0,arr1];
        },
        initsocket(){
            if(!window.WebSocket){
                window.WebSocket = window.MozWebSocket;
            }
            if(window.WebSocket){
                // wss://test.coalcloudtech.com/ws  测试
                // wss://scm.coalcloudtech.com/ws  生产
                // ws://119.45.238.116:12345/ws    开发
                this.socket = new WebSocket("wss://scm.coalcloudtech.com/ws");
                this.socket.onmessage = event => {
                    let arr = JSON.parse(event.data);
                    if(arr.alarmType){
                        // console.log('报警弹框');
                        // console.log(arr)
                        let myGeo = new BMap.Geocoder();
                        let pt = new BMap.Point(arr.alongitude,arr.alatitude);
                        myGeo.getLocation(pt,result =>{
                            let alarmTypename;
                            let html;
                            if(arr.alarmType == 1){
                                // console.log(111)
                                alarmTypename = '偏移报警';
                                html = '<div class="alertTile"></div><div class="alertTile_1">警告</div><div style="width:50%;font-weight: 600;font-size: 14px;color:#333333;float:left;line-height: 38px;">' + arr.licensePlate + '</div><div style="font-weight: 600;font-size: 14px;color:#333333;float:right;line-height: 38px;text-align:right;padding-right:24px;box-sizing: border-box;">速度 '+ arr.speed/10 +' Km/h</div><div style="width:50%;float:left;line-height: 28px;color:#333333;">报警类型:'+ alarmTypename +'</div><div style="width:50%;float:right;line-height: 28px;color:#333333;">偏移时长: --</div><div style="float:left;width:100%;line-height: 28px;color:#333333;">报警时间：'+ this.shijianc(arr.alocTime) +'</div><div style="float:left;width:100%;line-height: 28px;color:#333333;">报警位置：' + result.address + '</div><div style="float:left;width:50%;line-height: 28px;color:#333333;">司机：'+ arr.driverName +'</div><div style="float:left;width:50%;line-height: 28px;color:#333333;">电话:'+ arr.phone +'</div>'
                                // this.$notify({
                                //     dangerouslyUseHTMLString: true,
                                //     duration: 0,
                                //     customClass: 'notify-warning',
                                //     message: html,
                                //     offset: 80
                                // });
                            }else if(arr.alarmType == 2){
                                // console.log(222)
                                alarmTypename = '超时停车报警';
                                html = '<div class="alertTile"></div><div class="alertTile_1">警告</div><div style="width:50%;font-weight: 600;font-size: 14px;color:#333333;float:left;line-height: 38px;">' + arr.licensePlate + '</div><div style="font-weight: 600;font-size: 14px;color:#333333;float:right;line-height: 38px;text-align:right;padding-right:24px;box-sizing: border-box;">速度 '+ arr.speed/10 +' Km/h</div><div style="width:50%;float:left;line-height: 28px;color:#333333;">报警类型:'+ alarmTypename +'</div><div style="width:50%;float:right;line-height: 28px;color:#333333;">偏移时长: --</div><div style="float:left;width:100%;line-height: 28px;color:#333333;">报警时间：'+ this.shijianc(arr.alocTime) +'</div><div style="float:left;width:100%;line-height: 28px;color:#333333;">报警位置：' + result.address + '</div><div style="float:left;width:50%;line-height: 28px;color:#333333;">司机：'+ arr.driverName +'</div><div style="float:left;width:50%;line-height: 28px;color:#333333;">电话:'+ arr.phone +'</div>'
                                // this.$notify({
                                //     dangerouslyUseHTMLString: true,
                                //     duration: 0,
                                //     customClass: 'notify-warning',
                                //     message: html,
                                //     offset: 80
                                // });
                            }else if(arr.alarmType == 3){
                                // console.log(333)
                                alarmTypename = '超速报警';
                                html = '<div class="alertTile"></div><div class="alertTile_1">警告</div><div style="width:50%;font-weight: 600;font-size: 14px;color:#333333;float:left;line-height: 38px;">' + arr.licensePlate + '</div><div style="font-weight: 600;font-size: 14px;color:#333333;float:right;line-height: 38px;text-align:right;padding-right:24px;box-sizing: border-box;">速度 '+ arr.speed/10 +' Km/h</div><div style="width:50%;float:left;line-height: 28px;color:#333333;">报警类型:'+ alarmTypename +'</div><div style="width:50%;float:right;line-height: 28px;color:#333333;">偏移时长: --</div><div style="float:left;width:100%;line-height: 28px;color:#333333;">报警时间：'+ this.shijianc(arr.alocTime) +'</div><div style="float:left;width:100%;line-height: 28px;color:#333333;">报警位置：' + result.address + '</div><div style="float:left;width:50%;line-height: 28px;color:#333333;">司机：'+ arr.driverName +'</div><div style="float:left;width:50%;line-height: 28px;color:#333333;">电话:'+ arr.phone +'</div>'
                                // this.$notify({
                                //     dangerouslyUseHTMLString: true,
                                //     duration: 0,
                                //     customClass: 'notify-warning',
                                //     message: html,
                                //     offset: 80
                                // });
                            }else if(arr.alarmType == 4){
                                // console.log(444)
                                let newdate = arr.estimatedTime*1 + arr.estimatedTimeDeviation*1;
                                alarmTypename = '超时行驶报警';
                                html = '<div class="alertTile"></div><div class="alertTile_1">警告</div><div style="width:50%;font-weight: 600;font-size: 14px;color:#333333;float:left;line-height: 38px;">' + arr.licensePlate + '</div><div style="font-weight: 600;font-size: 14px;color:#333333;float:right;line-height: 38px;text-align:right;padding-right:24px;box-sizing: border-box;">速度 '+ arr.speed/10 +' Km/h</div><div style="width:50%;float:left;line-height: 28px;color:#333333;">报警类型:'+ alarmTypename +'</div><div style="width:50%;float:right;line-height: 28px;color:#333333;">偏移时长: --</div><div style="float:left;width:100%;line-height: 28px;color:#333333;">行驶时间：'+ arr.travelTime +' 分钟</div><div style="float:left;width:100%;line-height: 28px;color:#333333;">报警时间（预估时间+偏差时间）：' + newdate + ' 分钟</div><div style="float:left;width:50%;line-height: 28px;color:#333333;">司机：'+ arr.driverName +'</div><div style="float:left;width:50%;line-height: 28px;color:#333333;">电话:'+ arr.phone +'</div>'
                                // this.$notify({
                                //     dangerouslyUseHTMLString: true,
                                //     duration: 0,
                                //     customClass: 'notify-warning',
                                //     message: html,
                                //     offset: 80
                                // });
                            }
                       });
                        // this.$set(this.ArrsocketData,arr.licensePlate,arr);
                        // this.$set(this.centermap,'lng',arr.alongitude);
                        // this.$set(this.centermap,'lat',arr.alatitude);
                        // this.$set(this.ArrsocketData[arr.licensePlate],'lng',arr.alongitude);
                        // this.$set(this.ArrsocketData[arr.licensePlate],'lat',arr.alatitude);
                    }else{
                        // console.log(arr)
                        if(this.displayMap == 1){
                            // console.log(this.BAddress)
                            // console.log(arr)
                            if(this.BAddress.licensePlate == arr.licensePlate){
                                this.$set(this.ArrsocketData,arr.licensePlate,arr);
                                let array = [arr.lng,arr.lat];
                                var resultss = gcoord.transform(array,gcoord.WGS84,gcoord.BD09);
                                let myGeo = new BMap.Geocoder();
                                let pt = new BMap.Point(resultss[0],resultss[1]);
                                myGeo.getLocation(pt,result => {
                                    this.centermap = {
                                        lng:resultss[0],
                                        lat:resultss[1]
                                    }
                                    let qtiem = arr.createTime.substring(0,10);
                                    let htiem = arr.createTime.substring(11,19);
                                    let newtiem = qtiem + ' ' + htiem;
                                    this.markerPoint = {
                                        licensePlate:arr.licensePlate,
                                        lng:resultss[0],
                                        lat:resultss[1],
                                        createTime:newtiem,
                                        address:result.address,
                                    }
                                    this.$forceUpdate();
                                });
                            }

                        }
                    }
                };
                this.socket.onopen = event => {
                    // console.log('开启实时定位')
                    // console.log(event)
                    this.$message({
                        message: '已开启实时定位',
                        type: 'success'
                    });
                };
                // #连接关闭后的回调函数
                // this.socket.onclose = event => {
                //     this.$message({
                //         message: '已关闭',
                //         type: 'success'
                //     });
                //     console.log(e)
                // };
            }else{
                alert("您的浏览器不支持WebSocket协议！");
            }
            function send(message){
                if(!window.WebSocket){return;}
                if(socket.readyState == WebSocket.OPEN){
                    socket.send(message);
                }else{
                    alert("WebSocket 连接没有建立成功！");
                }
            }
        },
        shijianc(time) {
            let date = new Date(time*1000);
            // console.log(time)
            var Y = date.getFullYear() + '-'
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
            return Y + M + D + h + m + s
        },
        // 一级选项卡
        //选项卡
        select(item) {
            this.purSearch = "";
            this.sel = item.id;
            if(this.sel == 1){
                this.activeName = 'allline';
                this.shoe = true;
            }else if(this.sel == 2){
                this.handlerData(); // 渲染物流轨迹表格
            }
        },
        // 实时监控搜索功能
        search() {
            this.activeName = 'allline'
            this.mapAllData = [];
            this.getpurcontype();
            this.current = 1;
        },
        // 实时监控tab栏事件
        handleClick(tab) {
            this.purSearch = '';
            if(tab.name == 'allline'){
                this.getpurcontype();
            }else if(tab.name == 'online'){
                this.getpurcontypeone();
            }else if(tab.name == 'offline'){
                this.getpurcontypetwo();
            }
        },
        // 实时监控（全部）表格渲染
        getpurcontype(){
            // 传参
            let listByPageData = {
                searchStr:this.purSearch,
                page:this.current,
                size:this.size,
                ssjkFlag:true
            };
            this.api.riskCar.all(listByPageData)
            .then(res=>{
                if(res.data.code == 200){
                    this.mapAllData = res.data.data.dataList;
                    this.total = res.data.data.totalCount;
                }
            })    
        },
        // 实时监控（在线）表格渲染
        getpurcontypeone(){
            // 传参
            let listByPageData = {
                state:1,
                searchStr:this.purSearch,
                page:this.currentone,
                size:this.sizeone,
                ssjkFlag:true
            };
            this.api.riskCar.all(listByPageData)
            .then(res=>{
                if(res.data.code == 200){
                    this.mapAllDataone = res.data.data.dataList;
                    this.totalone = res.data.data.totalCount;
                }
            })    
        },
        // 实时监控（离线）表格渲染
        getpurcontypetwo(){
            // 传参
            let listByPageData = {
                state:2,
                searchStr:this.purSearch,
                page:this.currenttwo,
                size:this.sizetwo,
                ssjkFlag:true
            };
            this.api.riskCar.all(listByPageData)
            .then(res=>{
                if(res.data.code == 200){
                    this.mapAllDatatwo = res.data.data.dataList;
                    this.totaltwo = res.data.data.totalCount;
                }
            })    
        },
        // 选中车辆
        mapAllClick(data){
            // console.log(data)
            this.displayMap = 1;
            this.show = true;
            clearInterval(this.routerboday);
            console.log(this.routerboday)
            this.BAddress = data;
            // this.zoom = 18;
            // console.log(data)
            let results = gcoord.transform([data.lng,data.lat],gcoord.WGS84,gcoord.BD09);
            let myGeo = new BMap.Geocoder();
            let pt = new BMap.Point(results[0],results[1]);
            myGeo.getLocation(pt,result => {
                this.centermap = {
                    lng:results[0],
                    lat:results[1]
                }
                let qtiem = data.updateTime.substring(0,10);
                let htiem = data.updateTime.substring(11,19);
                let newtiem = qtiem + ' ' + htiem;
                this.markerPoint = {
                    licensePlate:data.licensePlate,
                    lng:results[0],
                    lat:results[1],
                    createTime:newtiem,
                    address:result.address,
                }
                this.$forceUpdate();
            });
        },
        infoWindowClose(){
            this.show = false;
        },
        /**
         * 跳转物流轨迹
        * */
        handlerClick(shoe){
            this.displayMap = 2;
            this.listSearch = this.BAddress.licensePlate;
            this.handlerData();
            this.sel = 2;
            this.trajectClick(this.BAddress.licensePlate,shoe);
        },
        // 通过时间获取围栏名称列表
        // fenceData(time){
        //     console.log(time);
        //     // fenceType:'',                           // 选中的围栏名称
        //     // fenceTypeoptions:[],                    // 围栏名称
        // },
        // 选中围栏的值
        wlType(e){
            console.log(e)
        },
        // 物流轨迹数据
        handlerData(){
            // 传参
            let listByPageData;
            // license_plate:this.BAddress.licensePlate,
            if(this.TimeArrays){
                listByPageData = {
                    startTime:this.TimeArrays[0],
                    endTime:this.TimeArrays[1],
                    searchStr:this.listSearch,
                    page:this.listcurrent,
                    size:this.listsize,
                    ssjkFlag:true
                };
            }else{
                listByPageData = {
                    searchStr:this.listSearch,
                    page:this.listcurrent,
                    size:this.listsize,
                    ssjkFlag:true
                };    
            }
            this.api.riskCar.all(listByPageData)
            .then(res=>{
                if(res.data.code == 200){
                    this.listmapData = res.data.data.dataList;
                    this.listtotal = res.data.data.totalCount;
                }
            }) 
        },
        // 轨迹查询搜索
        listsearchs(){
            this.listmapData = [];
            this.handlerData();
            this.listcurrent = 1;
        },
        // 轨迹查询日期搜索
        Times() {
            // this.fenceData(this.TimeArrays)
            this.listmapData = [];
            this.handlerData();
            this.listcurrent = 1;
        },
        // 查询单条轨迹
        trajectClick(vehicle,shoe){
            this.play = false;
            this.displayMap = 2;
            if(shoe != true){
                this.times();
            }
            this.zoom = 16;
            this.vehicles = vehicle;
            let strong = this.TimeArrays.toString();
            let vehiceData = {
                licensePlate:vehicle,
                time:strong,
                'time-op':'bt'
            }
            this.api.updatePwd.all(vehiceData)
            .then(res=>{
                // this.play = false;
                if(res.data.code == 200){
                    if(res.data.data.data.sjlist){
                        // res.data.data.data.sjlist.length
                        // this.play = true;
                        var result = null;
                        for(let i=0;i<res.data.data.data.sjlist.length;i++){
                                result = gcoord.transform(
                                [res.data.data.data.sjlist[i].lng,res.data.data.data.sjlist[i].lat],                  // 经纬度坐标
                                gcoord.WGS84,                   // 当前坐标系
                                gcoord.BD09                     // 目标坐标系
                            );
                            res.data.data.data.sjlist[i].lng = result[0];
                            res.data.data.data.sjlist[i].lat = result[1];
                            this.polylinePath.push({lng:result[0],lat:result[1]});
                            this.path.push({lng:result[0],lat:result[1]})
                        }
                        this.centermap = {
                            lng:this.polylinePath[0].lng,
                            lat:this.polylinePath[0].lat
                        }
                        // console.log(this.polylinePath)
                        // console.log(this.path)
                        this.$forceUpdate();
                    }
                }
            }) 
        },
        stopcars () {
            this.play = false;
        },
        gocars(){
            this.play = true;
        },
        // handleSearchComplete (res) {
        //     console.log(res)
        //     this.path = res.getPlan(0).getRoute(0).getPath()
        // },
        // 经纬度逆转析地址
        // maplng(lng,lat){
        //     let myGeo = new BMap.Geocoder();
        //     let pt = new BMap.Point(lng,lat);
        //     myGeo.getLocation(pt,result => {
        //         console.log(result.address)
        //         this.$set(this.startAddress,'lat',result.address);  // 获取到当前定位的详细地址信息
        //     });
        // }
    },
    destroyed(){
        this.socket.onclose = event => {};
    },
    // 销毁定时器
    beforeDestroy() {
        clearInterval(this.routerboday);
    }
};
</script>
<style lang="less" scoped>
  .pur-right {
    .el-input {
      width: 200px;
      float: right;
    }
    .el-button {
      margin-left: 16px;
      float: right;
    }
  }
  
// 一级选项卡开始
.menu-list{
    width: 100%;
    height: 48px;
    background-color: #fff;
    box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
    overflow: hidden;
}
.totalTab {
    width: 50%;
    float: left;
    font-size: 12px;
    line-height: 48px;
    color: #333;
    text-align: center;
}
.totalTab.active {
    background: #55abf7;
    color: #fff;
}
.parNavs{
    width: 100%;
    .mapSearch{
        width: 100%;
        overflow: hidden;
        padding: 12px 12px 0;
        box-sizing: border-box;
    }
}
// 一级选项卡结束
// 实时监控内容开始
.maptableAll{
    width: 100%;
    height: 350px;
    padding: 0 8px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: scroll;
    ul:hover{
        background: #55abf7;
        color: #fff;
    }
    ul{
        width: 100%;
        height: 36px;
        border-bottom:1px solid rgb(236, 233, 233);
        box-sizing: border-box;
        list-style: none;
        li{
            font-size: 14px;
            font-weight: 600;
            line-height: 36px;
            color: #333;
            float: left;
            cursor: pointer;
        }
    }
}
.mapPages{
    width: 100%;
    padding: 16px 8px 0 0;
    box-sizing: border-box;
    .el-pagination{
        float: right;
    }
}
// 实时监控内容结束
// 实时监控弹框样式开始
.infobmBox{
    width: 100%;
    height: 200px;
    .infobmTitle{
        width: 100%;
        height: 20px;
        margin-bottom:12px;
        box-sizing: border-box;
        i{
            font-size: 18px;
            color: #999;
            line-height: 20px;
        }
        span{
            font-size: 16px;
            color: #999;
            line-height: 20px; 
        }
    }
    .infobmConter{
        height: 168px;
        overflow: hidden;
        overflow-y: scroll;
        ul{
            width: 100%;
            height: 30px;
            box-sizing: border-box;
            list-style: none;
            li{
                width: 75%;
                font-size: 14px;
                line-height: 30px;
                color: #333;
                float: left;
            }
        }
    }
    .infobmBottom{
        width: 100%;
        height: 30px;
        padding-top:10px;
        box-sizing: border-box;
        .infobottomright{
            width: auto;
            height: 100%;
            float: right;
            cursor: pointer;
            i{
                font-size: 16px;
                color: #999;
                line-height: 20px;
            }
            span{
                font-size: 16px;
                color: #999;
                line-height: 20px; 
            }
        }
    }
}
// 实时监控弹框样式结束
// 轨迹查询开始
.trajectoryBox{
    width: 100%;
    height: 360px;
    padding: 8px 8px 0;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: scroll;
    ul{
        width: 100%;
        height: 36px;
        border-bottom:1px solid rgb(236, 233, 233);
        box-sizing: border-box;
        list-style: none;
        cursor: pointer;
        li{
            width: 15%;
            font-size: 14px;
            font-weight: 600;
            line-height: 36px;
            color: #333;
            float: left;
        }
    }
}
.trajmapPages{
    width: 100%;
    overflow: hidden;
    padding: 16px 8px 0 0;
    box-sizing: border-box;
    .el-pagination{
        float: right;
    }
}
// 轨迹查询结束
// 路线绑定样式开始
.Alignment{
    width: 100%;
    height: 491px;
    padding: 24px 12px;
    box-sizing: border-box;
    .el-input{
        margin: 12px 0;
    }
}
// 路线绑定样式结束
// 头部开始
.pur-top {
  width: 100%;
  height: 50px;
  padding: 9px 24px 9px 32px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  border: 1px solid rgba(102, 102, 102, 0.15);
  .pur-size {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    font-weight: 600;
  }
}
// 头部结束
// 内容开始
.pur-nav {
  width: calc(100% - 32px);
  height: calc(100vh - 142px);
  background-color: #fff;
  margin: 8px;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  padding: 8px;
  position: relative;
  .newBoxmap{
    width: 100%;
    height: 100%;
  }
  .bm-view{
    width: 100%;
    height: 100%;
  }
  .mapBox{
      width: 342px;
      position: absolute;
      top: 16px;
      left:16px;
      z-index: 999;
    /deep/ .el-collapse-item__header{
        background-color: #0a8cff;
        color: #fff;
        font-size: 16px;
    }
    .mapsize{
        width: 100%;
        text-align:center;

    }  
  }
}
// 内容结束
</style>